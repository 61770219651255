<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in bg-gray-3">
		
		<a-row type="flex" :gutter="24" justify="space-around" align="middle" style="padding-top: 100px;">

			<a-col :span="24" :sm="24" :md="18" :lg="16" :xl="14" class="col-form mt-20">
				
				<a-card :bordered="false" class="card-info px-5 mx-0 pt-20">
					<a-row :gutter="[24,24]">
						<!-- <a-button id="btnBordered" type="text" class="primary ml-15 mb-20" v-if="current > 0 && current != 2" @click="previousScreen"><a-icon type="arrow-left" theme="outlined" /> &nbsp;&nbsp; Back  </a-button> -->
						<a-col :span="24">
							<a-row class="mt-0 pt-2">
								<a-col :span="24" class="px-10">
									<!-- <a-progress strokeColor="#734EBF" trailColor="success" :percent="Math.floor(((current + 1) / 4) * 100)" /> -->
								</a-col>
							</a-row>
							<!-- <a-steps :current="current">
								<a-step v-for="item in steps" :key="item.title" :title="item.title" />
							</a-steps> -->
							<div class="steps-content">
								<!-- {{ steps[current].content }} -->
								<template>
									<a-row :gutter="24">
										
										<a-col :span="24" :md="24" :lg="24">

											<a-row type="flex" :gutter="24" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==0">
												<a-col :span="24" :xs="24" :sm="24" :md="20" :lg="20" :xl="{span: 20, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 20px;">
																<strong>Access Code</strong>
															</label>
															<p class="mt-10">Please enter the acccess code you received via sms/email so as to proceed</p>
														</a-col>
													</a-row>

													<a-alert
														v-if="errorAccessCode.show"
														class="mx-20"
														:message="errorAccessCode.message"
														type="error"
														closable
														:after-close="handleCloseError"
														show-icon 
													/>

													<a-row :gutter="24" v-if="!loadingData">
														<a-col :span="24">
															<a-row type="flex" :gutter="24" justify="space-around" align="middle">
																<a-col :span="24" :xs="24" :sm="24" :md="12" class="mb-20 py-0">
																	<a-form
																		id="components-form-demo-normal-login"
																		:form="passcodeForm"
																		class="login-form mt-20 pt-20"
																		@submit="handleSubmitPasscode"
																		:hideRequiredMark="true">

																		<a-row :gutter="[24,24]">
																			<a-col :span="24" :sm="24" :md="24" :lg="24">
																				<a-form-item class="py-0 my-0" label="" :colon="false">
																					<a-input
																						v-decorator="[
																							'passcode', 
																							{ rules: [{ required: true, message: 'Please enter acccess code to proceed' }] }, 
																						]" 
																						type="number" 
																						placeholder="Enter access code" />
																				</a-form-item>
																			</a-col>
																		</a-row>
																		
																		<a-form-item class="mt-2 text-center mt-20 pt-15">
																			<a-button type="primary" style="width: 100px !important;" block html-type="submit" class="login-form-button mt-10 text-white" >
																				NEXT
																			</a-button>
																		</a-form-item>
																		
																	</a-form>
																</a-col>

																
															</a-row>

														</a-col>
													</a-row>

													<div v-if="loadingData">

														<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 10vh">

															<a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">

																<a-spin class="text-primary" />

															</a-col>

														</a-row>
													</div>

													<!-- <a-row class="mt-20 pt-10 px-20 mx-20 mb-10">
														<a-col :span="24" class="text-center pr-10">
															<a-button type="primary" v-if="newUser.role != null" class="" @click="submitRole"> Next <a-icon type="arrow-right" theme="outlined" /> &nbsp;&nbsp; </a-button>
														</a-col>
													</a-row> -->
												</a-col>
											</a-row>

											<a-row type="flex" :gutter="24" justify="space-around" align="top" style="margin-top: 50px;" v-if="current==1">
												<a-col :span="24" :sm="24" :md="20" :lg="20" :xl="{span: 20, offset: 0}" class="col-form pt-2">

													<a-row ty :gutter="24" class="text-center">
														<a-col :span="24">
															<label class="text-center mt-0 pt-0" style="font-size: 20px;">
																<strong>Class Details</strong>
															</label>
														</a-col>
													</a-row>


													<a-row type="flex" :gutter="24" class="pb-4 mt-24">

														<!-- <a-col :span="24" :md="24" class="mb-24 mt-24">
															<a-descriptions title="Student Info" layout="horizontal" bordered size="small"> -->
																<!-- <a-descriptions-item label="Student" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ newClass.studentDetails != null ? `${newClass.studentDetails.firstName} ${newClass.studentDetails.lastName}` : 'N/A' }}
																</a-descriptions-item> -->
																<!-- <a-descriptions-item label="Gender" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.gender != null ? requestDetails.student.gender : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="School" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.school != null ? requestDetails.student.school : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Location" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.location != null ? requestDetails.student.location : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Physical Address" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.physicalAddress != null ? requestDetails.student.physicalAddress : '' }}
																</a-descriptions-item> -->
															<!-- </a-descriptions>
														</a-col> -->

														<a-col :span="24" :md="24" class="mt-20">
															<a-descriptions title="" layout="horizontal" bordered size="small">

																<a-descriptions-item label="Student Gender" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.gender != null ? requestDetails.student.gender : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Student School" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.school != null ? requestDetails.student.school : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Student Location" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.location != null ? requestDetails.student.location : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Student Physical Address" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.student.physicalAddress != null ? requestDetails.student.physicalAddress : '' }}
																</a-descriptions-item>
				
																<a-descriptions-item label="Start Date" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.clazz.startDate != null ? $Moment(requestDetails.clazz.startDate).format('DD MMM YYYY') : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="End Date" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.clazz.endDate != null ? $Moment(requestDetails.clazz.endDate).format('DD MMM YYYY') : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Education System" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.clazz.educationSystem != null ? requestDetails.clazz.educationSystem.name : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Education Level" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.clazz.educationLevel.name != null ? requestDetails.clazz.educationLevel.name : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Education Grade" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	{{ requestDetails.clazz.educationGrade.name != null ? requestDetails.clazz.educationGrade.name : '' }}
																</a-descriptions-item>
																<a-descriptions-item label="Subjects" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	<a-badge 
																		v-for="option in (requestDetails.clazz.subjects != null ? requestDetails.clazz.subjects : [])" 
																		:key="option.uuid"
																		style="font-size: 10px !important; margin-right: 5px;" 
																		:count="option.name" 
																		:number-style="{ backgroundColor: '#D9D9D9', color: '#444'}"/>
																</a-descriptions-item>
																<a-descriptions-item label="Status" :label-style="{ width: '40%', fontWeight: 'bold' }" :span="3">
																	<a-badge  
																		style="font-size: 10px !important;" 
																		:count="requestDetails.status != null ? formatName(requestDetails.status) : ''" 
																		:number-style="requestDetails.status == 'approved' ? { backgroundColor: '#00A800', color: '#fff', boxShadow: '0 0 0 1px #734EBF inset'} : { backgroundColor: '#D9D9D9', color: '#444' }"/>
																</a-descriptions-item>
															</a-descriptions>
														</a-col>

													</a-row>


													<a-row type="flex" :gutter="24" class="pb-4 mt-24">

														<a-col :span="24" :md="24" class="mt-20 py-20 text-center">
															<a-button id="btnSuccess" type="primary" size="small" @click="submitTutorResponse('approved')">Approve Request</a-button>
															&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
															<a-button id="btnDanger" type="primary" size="small" @click="submitTutorResponse('rejected')">Reject Request</a-button>
														</a-col>

													</a-row>


													<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
															<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->

													
												</a-col>
											</a-row>
														
										</a-col>
									</a-row>
								</template>
							</div>
							<div class="steps-action">
								<!-- <a-button v-if="current < steps.length - 1" type="primary" @click="next">Next</a-button>
								
								<a-button v-if="current > 0" style="margin-left: 8px" @click="prev">Previous</a-button> -->
							</div>
						</a-col>
					</a-row>
					

					
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	import VueJwtDecode from 'vue-jwt-decode'
	import VueCountryCode from "vue-country-code-select";
    import { notification } from 'ant-design-vue';

	export default ({
		components: {
			VueCountryCode
		},

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				// form: null,
				// formPassword: null,

				

				genders: [
					{ uuid: false, name: "Male" },
					{ uuid: true, name: "Female" },
				],

				current: 1,
				steps: [
					{
						title: 'Who are you?',
						content: 'First-content',
					},
					{
						title: 'Basic Details',
						content: 'First-content',
					},
					{
						title: 'Account Details',
						content: 'Second-content',
					},
					// {
					// 	title: 'Password',
					// 	content: 'Third-content',
					// },
					{
						title: 'Verify Account',
						content: 'Third-content',
					},
				],


				loadingData: false,

				

				requestDetails:  {
					uuid: null,
				 	passcode: null,
					clazz: {},
					student: {},
					status: null,
				},
				
				errorAccessCode: {
					message: null,
					show: false,
				},

				

				
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.passcodeForm = this.$form.createForm(this, { name: 'passcode_form' });
		},
		created() {
			this.getTutorRequest();
		},
		methods: {

			next() {
				this.current++;
			},

			previousScreen() {
				if(this.current > 0) {
					this.current--;
				}
			},

			formatName(name) {
				let formattedName = name != null && name.length > 1 ?`${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : '';
				return formattedName;
			},

			handleCloseError() {
				this.errorAccessCode.show = false;
			},

			async getTutorRequest() {

				this.loadingData = true;

                let url = `${this.$BACKEND_URL}/profiles/lead-tutor-requests?uuid=${this.$route.params.uuid}&populate=true`;

				this.$AXIOS.get(url).then((response) => {

					if(response.data.data != null) {

						this.requestDetails.uuid = response.data.data.uuid;
						this.requestDetails.passcode = response.data.data.passcode;
						this.requestDetails.clazz = response.data.data.clazz;
						this.requestDetails.student = response.data.data.student;
						this.requestDetails.status = response.data.data.status;

						this.loadingData = false;
						
					}
					
                }).catch(async(error) => {
					this.loadingData = false;
                });
            },
			

			handleSubmitPasscode(e) {
				e.preventDefault();
				
				this.passcodeForm.validateFields((err, values) => {
					if ( !err ) {

						let payload = values;

						if(this.requestDetails.passcode == payload.passcode) {
							this.current++;
						}else{
							this.errorAccessCode.message = 'Wrong or Invalid passcode';
							this.errorAccessCode.show = true;
						}
						
					}
				});
			},



			submitTutorResponse(status) {

				// this.newUser.usernameLoading = true
					
				let url = `${this.$BACKEND_URL}/profiles/lead-tutor-requests/response/${this.$route.params.uuid}`;

				let payload = {
					clazz: this.requestDetails.clazz.uuid,
					status: status,
					responseDate: this.$Moment()
				}
				
				this.$AXIOS.patch(url, payload).then(async(response) => {
					if (response.status >= 200 && response.status < 210) {

						this.$router.push('/')
						
					}
					// this.newUser.usernameLoading = false;
				}).catch((err) => {
					console.log(err)
				
				});
					
			},


			notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	.ant-steps-item-process .ant-steps-item-icon { background: red; }
	#signinBtn, #signinBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#submitUsernameBtn, #submitUsernameBtn:hover, #submitTokenBtn, #submitTokenBtn:hover {
		background-color: #632163 !important;
		color: white !important;
	}
	#backBtn, #backBtn:hover {
		color: #632163 !important;
		border-color: #632163 !important;
		background-color: white !important;
	}

	.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: red !important;
}

.custome-step .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: red !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
//    background-color: #632163 !important;
   color: #ffffff;
}

.ant-steps-item-process .ant-steps-item-icon {
    border-color: #632163 !important;
    background: #632163 !important;
}

#divId {
	display: inline-block !important;
}
</style>
